
import React from 'react';
import MainRoutes from './Routes/MainRoutes';

export default function App() {
    return (
       
        <React.StrictMode>
            <MainRoutes />     
        </React.StrictMode>
      
    );
}



