import React from 'react'
import Header from '../home/Header/Header'
import HeaderBottom from '../home/Header/HeaderBottom'
import Footer from '../home/Footer/Footer'
import FooterBottom from '../home/Footer/FooterBottom'
import Navigation from '../home/Header/Navigation'

export default function Privacypolicy() {
  return (
    <div>
        <Header />
        <HeaderBottom />
        <div className="lg:container mx-auto px-4 py-8 font-body3">
          <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
          <p className="mb-4">
            Welcome to Fresh Bells! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website and make purchases from our e-commerce platform.
          </p>
          <h2 className="text-2xl font-semibold mb-2">Information We Collect</h2>
          <p className="mb-4">
            We collect various types of information to provide and improve our services to you:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Personal Information: When you create an account, place an order, or subscribe to our newsletter, we may collect personal details such as your name, email address, phone number, and shipping address.</li>
            <li>Payment Information: We collect payment details to process your orders securely. This includes credit card information, billing address, and other payment-related data.</li>
            <li>Usage Data: We collect information about how you interact with our website, including your IP address, browser type, pages visited, and the time and date of your visit.</li>
          </ul>
          <h2 className="text-2xl font-semibold mb-2">How We Use Your Information</h2>
          <p className="mb-4">
            We use the collected information for various purposes, including:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Processing and fulfilling your orders</li>
            <li>Providing customer support and responding to your inquiries</li>
            <li>Sending you updates, promotions, and newsletters (you can opt-out at any time)</li>
            <li>Improving our website, products, and services</li>
            <li>Ensuring the security and integrity of our platform</li>
          </ul>
          <h2 className="text-2xl font-semibold mb-2">Data Security</h2>
          <p className="mb-4">
            We implement a variety of security measures to protect your personal information. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </p>
          <h2 className="text-2xl font-semibold mb-2">Third-Party Services</h2>
          <p className="mb-4">
            We may use third-party services to facilitate our business operations, such as payment processors and analytics providers. These third parties have access to your personal information only to perform specific tasks on our behalf and are obligated to protect your data.
          </p>
          <h2 className="text-2xl font-semibold mb-2">Your Rights</h2>
          <p className="mb-4">
            You have the right to access, update, or delete your personal information. If you wish to exercise any of these rights, please contact us at support@freshbells.com.
          </p>
          <h2 className="text-2xl font-semibold mb-2">Changes to This Privacy Policy</h2>
          <p className="mb-4">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review this Privacy Policy periodically for any updates.
          </p>
          <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
          <p className="mb-4">
            If you have any questions or concerns about our Privacy Policy, please contact us at support@freshbells.com.
          </p>
        </div>
        <Footer/>
        <FooterBottom />
        <div className="block lg:hidden overflow-hidden mt-16" >
        <Navigation />
      </div>
    </div>
  )
}
