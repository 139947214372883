import React from 'react'
import Header from '../home/Header/Header'
import HeaderBottom from '../home/Header/HeaderBottom'
import Footer from '../home/Footer/Footer'
import FooterBottom from '../home/Footer/FooterBottom'
import Navigation from '../home/Header/Navigation'
import banner12 from "../../assets/bg/dietician.jpg"
import { AiOutlineArrowRight } from 'react-icons/ai'
import bggain from "../../assets/images/banner/bg-1.jpg"

export default function Dietician() {
    return (
        <div>
            <Header />
            <HeaderBottom />
            <div style={{ backgroundImage: `url(${banner12})`, backgroundSize: 'cover' }}>
                <div className="xl:container mx-auto px-4 py-8 font-body3">
                    <h1 className="text-4xl font-bold text-center mb-6">Welcome to Your Path to Optimal Health</h1>
                    <p className="text-lg text-center mb-4">Are you ready to revolutionize the way you feel inside and out? At Fresh Bells, we believe in the power of personalized nutrition to transform lives. Our expert dietician consultant offers customized plans designed to meet your unique needs, goals, and lifestyle.</p>
                    <h2 className="text-3xl font-semibold text-center mb-4">Why Choose Us?</h2>
                    <ul className="list-disc list-inside mb-6">
                        <li className="text-lg mb-2"><strong>Tailored Nutrition Plans:</strong> No two bodies are alike, and neither are our nutrition plans. Get advice and meal plans that are specifically crafted for you.</li>
                        <li className="text-lg mb-2"><strong>Expert Guidance:</strong> Benefit from the experience and knowledge of a certified dietician who is dedicated to your success.</li>
                        <li className="text-lg mb-2"><strong>Sustainable Results:</strong> We focus on long-term, sustainable changes that fit seamlessly into your life, rather than quick fixes.</li>
                    </ul>
                    <h2 className="text-3xl font-semibold text-center mb-4">Start Your Journey Today</h2>
                    <p className="text-lg text-center mb-4">Achieving optimal health and wellness is just a consultation away. Discover how our holistic approach to nutrition can help you live your healthiest life. Contact us today to book your initial consultation and take the first step toward a healthier, happier you.</p>
                </div>
                <section className="w-full p-8">
                    <div className="text-center mb-8">
                        <h1 className="text-4xl font-semibold text-primeColor">Your Journey with Fresh Bells - A Roadmap to Healthy You</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                        <div className="flex flex-col items-center bg-blue-100 rounded-2xl p-6 shadow-lg hover:scale-105 transition-transform duration-300 cursor-pointer">
                            <div className="text-4xl text-black mb-4">1</div>
                            <p className="text-gray-600 text-center">The customer reaches out to Fresh Bells via website to select desired products or diet combo packages depending on their needs.</p>
                        </div>
                        <div className="flex flex-col items-center bg-yellow-200 rounded-2xl p-6 shadow-lg hover:scale-105 transition-transform duration-300 cursor-pointer">
                            <div className="text-4xl text-black mb-4">2</div>
                            <p className="text-gray-600 text-center">Customers can select the appropriate combo packages for their dietary needs designed by a team of diet & nutrition experts.</p>
                        </div>
                        <div className="flex flex-col items-center bg-pink-200 rounded-2xl p-6 shadow-lg hover:scale-105 transition-transform duration-300 cursor-pointer">
                            <div className="text-4xl text-black mb-4">3</div>
                            <p className="text-gray-600 text-center">If customers need any special consultations they can reach out to our experts. Our team will guide you by providing appropriate suggestions and advice after a detailed consultation.</p>
                        </div>
                        <div className="flex flex-col items-center bg-red-200 rounded-2xl p-6 shadow-lg hover:scale-105 transition-transform duration-300 cursor-pointer">
                            <div className="text-4xl text-black mb-4">4</div>
                            <p className="text-gray-600 text-center">Once you have completed the checkout and payment process we will deliver the products at your doorsteps.</p>
                        </div>
                    </div>
                    <p className="text-blue-500 font-semibold text-center mt-4">Note: If you are buying any of our trial packs, you are eligible for a free consultation worth Rs. 1000 within 10 days of delivery. (Conditions Apply)</p>
                </section>
                <div className='relative flex justify-center items-center h-screen'>
                    <div className='lg:w-[1000px] xs:w-full h-auto rounded-lg shadow-sm shadow-primeColor flex lg:flex-row xs:flex-col'>
                        <img src={bggain} alt='Best Diet Plans' className='lg:w-1/2 xs:w-full h-full object-cover rounded-lg' />
                        <div className='lg:w-1/2 xs:w-full bg-white flex flex-col justify-center items-center p-4'>
                            <h2 className='text-primeColor lg:text-4xl xl:text-4xl md:text-2xl sm:text-base xs:text-base font-body3 font-medium text-center'>Contact Our Best Nutritionists and Dietitians</h2>
                            <div className='flex items-center gap-2 mt-4'>
                                <a href="mailto:dietician@freshbells.in" className='bg-primeColor font-body3 text-white py-2 px-4 rounded-full flex items-center justify-center hover:bg-black hover:text-white transition-colors'>
                                    Email Us <AiOutlineArrowRight className='ml-2' />
                                </a>
                                <a href="https://wa.me/919495967722" className='bg-primeColor font-body3 text-white py-2 px-4 rounded-full flex items-center justify-center hover:bg-black hover:text-white transition-colors'>
                                    WhatsApp <AiOutlineArrowRight className='ml-2' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <FooterBottom />
            <div className="block lg:hidden overflow-hidden mt-16" >
                <Navigation />
            </div>
        </div>
    )
}
